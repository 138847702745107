import { Form } from "vee-validate";
import * as yup from "yup";
import SSNLogin from "@/components/SSNLogin.vue";
import changeLanguage from "@/components/changeLanguage.vue";
import auth from "@/api/auth";
import "./login.css";
import router from "@/router";
import InputWithValidation from "@/components/inputWidthvalidate.vue";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "login",
  components: {
    Form,
    SSNLogin,
    changeLanguage,
    InputWithValidation,
    VueRecaptcha,
  },
  data() {
    return {
      formValues: {
        name: "",
        password: "",
      },
      captchaSiteID: '6LefwDgoAAAAABTWI-y3-O103ePaxrJwM5-lWjQz',
      isShowCaptcha: false,
      consecutiveFailed: 0,
      captchaResponse: '',
      numberTriggerCaptcha: 5
    };
  },
  setup() {
    const login = yup.object({
      ten: yup.string().required("Tên đăng nhập là một trường bắt buộc"),
      matKhau: yup
        .string()
        .min(8, "Mật khẩu ít nhất 8 ký tự")
        .required("Mật khẩu là một trường bắt buộc"),
    });

    return {
      login,
    };
  },
  methods: {
    async signin() {
      if (localStorage.getItem("token")) {
        this.$router.push({ name: "companyManagement" });
        return;
      }
      const data = {
        user_name: this.formValues.name,
        password: this.formValues.password,
        captcha_response: this.captchaResponse
      };
      if (this.consecutiveFailed >= this.numberTriggerCaptcha && !data['captcha_response']) {
        data['captcha_response'] = 'failed_captcha'
      }
      const result = await this.$request({
        url: auth.LOGIN,
        method: "POST",
        data,
      });
      const res = result.data;
      if (res.code == 200) {
        this.$store.dispatch("setToken", res.data.token);
        localStorage.setItem("expired_at", res.data.expired_at);
        this.isShowCaptcha = false
        this.consecutiveFailed = 0
        router.push({ name: "companyManagement" });
      }

      if (res.code == 403) {
        this.$swal({
          text: res.messages,
          icon: "error",
          timer: "3000",
          width: "450",
          padding: "10px",
        });
        this.consecutiveFailed += 1
        if (this.consecutiveFailed >= this.numberTriggerCaptcha) {
          this.isShowCaptcha = true
          this.$refs.recaptcha.reset();
        }
      }

      if (res.code == 400) {
        if (res.errors.force != undefined) {
          this.$swal({
            html: res.messages,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3b82f6",
            cancelButtonColor: "#adadad",
            confirmButtonText: "Tiếp tục",
            cancelButtonText: "Hủy!",
            reverseButtons: true,
            width: "450",
            padding: "10px",
          }).then(async (result) => {
            if (result.isConfirmed) {
              const data = {
                user_name: this.formValues.name,
                password: this.formValues.password,
                force: true,
              };
              const result = await this.$request({
                url: auth.LOGIN,
                method: "POST",
                data,
              });
              const res = result.data;
              if (res.code == 200 && res.success) {
                this.$store.dispatch("setToken", res.data.token);
                localStorage.setItem("expired_at", res.data.expired_at);
                router.push({ name: "companyManagement" });
              }
            }
          });
        } else {
          this.$store.dispatch("setEmailUser", res.errors.email);
          this.$store.dispatch("setEmailQltk", res.errors.email);
          router.push({ name: "sendEmail" });
        }
      }
    },
    verifyCaptcha(e) {
      this.captchaResponse = e
    },
  },
};
